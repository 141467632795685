<template>
  <div :class="clase">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center p-0">
          <div class="tb-banner"><img srcset="" :src="urlImg" alt=" " class="d-none d-md-block img-fluid mx-auto" /> <img srcset="" :src="urlImgMovil" alt=" " class="d-md-none img-fluid mx-auto" /></div>
          <div class="p-4">
          <Trivia v-if="triviaStatus" :c13="c13" :got="got" :clasetext="clasetext" @changeValue="updateParentValue"/>
          <Mensaje v-if="modal" :clasetext="clasetext" @changeValue="updateParentValue"/>
            <div v-else-if="!modal && !triviaStatus">
              <h1 :class="{ 'py-5': true, [clasetextGracias]: true }">¡Gracias!</h1>
              <h2 :class="clasetextGracias">Participación realizada exitosamente, acabas de sumar {{ opciones }} opciones de ganar.</h2>
              <h2 :class="clasetextGracias" style=" font-size: 18px">Pago procesado con {{ status }} para {{ opccion }} con nc {{ nc }}.</h2>
            </div>
          </div>
          <div class="bg-black text-white fixed-bottom">
            <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Trivia from "@/components/Trivia.vue";
import Mensaje from "@/components/Mensaje.vue";

export default {
  name: 'HomeView',
  components: {
    Trivia,
    Mensaje,
  },
  data() {
    return {
      clase: null,
      clasetext: null,
      clasetextGracias: null,
      urlImg: null,
      urlImgMovil: null,
      c13: false,
      got: false,
      triviaStatus: false,
      status: null,
      opccion: null,
      nc: null,
      modal: false,
      opciones: null,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    updateParentValue(data) {
      this.triviaStatus = data.triviaStatus;
      this.modal = data.modal;
      if (this.modal) {
        this.time();
      }
    },
    time() {
      setTimeout(() => {
        // Cambiar el valor de la variable después de 3 segundos (3000 ms)
        this.modal = false;
        this.triviaStatus = true;
      }, 3000); // Cambiar el tiempo (en milisegundos) según tus necesidades
    }
  },
  mounted() {
    this.opciones = this.$route.query.opciones;
    if (this.$route.query.status === "success" && this.$route.query.opccion) {
      this.status = this.$route.query.status;
      this.opccion = this.$route.query.opccion;
      this.nc = this.$route.query.nc;
      this.triviaStatus = true;
      if (this.$route.query.opccion.includes("Concurso 13")) {
        this.clase = 'lp-c13-generico vh-100';
        this.clasetext = 'text-black-50 text-uppercase h5';
        this.clasetextGracias = 'text-black-50';
        this.c13 = true;
        this.urlImg = require('@/assets/galeria/banner_c13_desktop.png');
        this.urlImgMovil = require('@/assets/galeria/banner_c13_mobile.png');
      }else if(this.opccion.toLowerCase().includes("got")) {
        this.clase = 'bg-dark got-landing bg-gradiente-azul-oscuro-a';
        this.clasetext = 'text-white text-uppercase h5';
        this.clasetextGracias = 'text-white';
        this.got = true;
        this.urlImg = require('@/assets/galeria/lnd_got_header_hq.png');
        this.urlImgMovil = require('@/assets/galeria/lnd_got_header_hq.png');
      }else {
        this.clase = 'bg-tierra-brava vh-100';
        this.clasetext = 'text-white text-uppercase h5';
        this.clasetextGracias = 'text-white';
        this.urlImg = require('@/assets/galeria/img_tb_main_desktop.jpg');
        this.urlImgMovil = require('@/assets/galeria/img_tb_main.jpg');
      }
    } else {
      this.$router.push('/');
    }
  },
}

</script>
